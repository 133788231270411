import { Component, OnInit, OnDestroy } from '@angular/core';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})

export class HelpComponent implements OnInit,OnDestroy {
  selectedTab:any
  generalIssues: any = [];
  legelTermsCondition: any = {};
  legelPrivacyCondition: any = {};
  legelRefundCondition: any = {};
  pastOrderList: any = [];
  selectedItemIndex: any = null;
  selectedOrderObj: any = {};
  display: any;
  selectedItem: any={};
  faqArr: any=[];
  message: any;
  orderDetail: any={};
  getTax: any;
  getDeliveryCharge: any;
  pastThreadArr: any =[];
  chatTitle: any;
  totalItems: any=0;
  limit: any=10;
  pageSize:number=10
  itemsPerPage: number = 5
  pageNumber:number=1
  helpOrderQues_Arr :any = [
    {
      'question': "I haven't received this order",
      'answer': "We are really sorry for this experience. You can try reaching out to our delivery executive or us and we will try to resolve this as soon as possible."
    },
    {
      'question': "Items are missing from my order",
      'answer' : "We are really sorry that you had this experience. Please reach out to us and we will try to resolve this immediately",
    },
    {
      'question': "Items are different from what I ordered",
      'answer' : "We are really sorry that incorrect items were delivered in your order. Please reach out to us and we will try to resolve this immediately",
    },
    {
      'question': "I have packaging or spillage issue with this order",
      'answer': "We are really sorry that your order had packaging issues. Please reach out to us and we will try to resolve this immediately",
    },
    {
      'question': "I have received bad quality food",
      'answer': "Please let us know what items were of bad quality. And we will try to resolve this immediately",
    },
    {
      'question': "The quantity of food is not adequate",
      'answer': "Please let us know what items were inadequate. And we will try to resolve this immediately",
    }

  ]

  myUserId: any;
  imageUrl: any = '';
  cartArr: any=[];
  rest_id: any;
  selectedItemsArray: any=[];
  cartSubTotal: any = 0;
  location: string;
  room_id: any;
  receiverId: any;
  sub:any;
  chat_type: string;
  active_generalIsuue_Chat: boolean=false;
  token:any;
  url: string;
 
  constructor(public service: DataserviceService, public spinner: NgxSpinnerService, private router: Router) { 
    this.selectedTab = localStorage.getItem('token') ? 'Help' : 'Terms'
    this.myUserId = localStorage.getItem('user_id');
    this.location = localStorage.getItem('address')
    this.token = localStorage.getItem('token');

    this.cartArr = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')): [];
    console.log( this.cartArr.length);
    if(this.cartArr.length > 0) {
      this.rest_id  = this.cartArr[0].restaurant_id;
      console.log( this.rest_id)
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.helpWithOrders();
    // this.getTaxDetails();
    // this.getDeliveryCharges();
    this.checkCart();
    // this.applyDiscount();
    this.getChatList()
  }

  getChatList() {
    if(localStorage.getItem('token')){
      this.service.getApi('chat/chat-list').subscribe(res=>{
        if(res.status ==200) {
          console.log(res)
        }
      })
    }
    
  }

  ngOnDestroy(){
    $('#chat-modal').modal('hide')
    if(this.room_id){
      this.closeChat()
    }
  }

 helpWithOrders () {
   this.url =  "https://apifooddelivery.mobiloitte.com/"
   if(localStorage.getItem('token')){
    this.selectedItemIndex = null
    //if (localStorage.getItem('token')){
    this.spinner.show();
    this.service.getApi('customer/order-history').subscribe((res: any) => {
    this.spinner.hide();
      if (res.status == 200) {
        this.pastOrderList = res.body;
        // this.service.showSuccess(res.body.message)
        
      }
    }, error => {
      setTimeout(() => {
        
        this.spinner.hide();
      }, 3000);
      console.log('error', error)
      if (error.status == 401) {
        this.service.toastErr(error.error.detail);  
      }else if(error.status == 403){
        this.service.toastErr(error.error.detail)
        this.router.navigate([''])
      }
    })
   }
    
  }
  //}

  // Get tax details api integration
  getTaxDetails() {
    this.service.getApi('api/tax').subscribe((res:any) => {
      if(res.status == 200){
        this.getTax = res.body.tax_percentage
      }
    })
}
  // Get getDeliveryCharges details api integration

getDeliveryCharges() {
  this.service.getApi('api/delivery-charges').subscribe((res:any) => {
    if(res.status == 200){
      this.getDeliveryCharge = res.body.charges
    }
  })
}


  // viewOrderDetail(order_id: any) {
  //   let url = "customer/order-detail";
  //   //if (localStorage.getItem('token')){
  //   console.log('id', order_id)
  //   this.spinner.show();
  //   this.service.getApi(`${url}/${order_id}`, 1).subscribe((res: any) => {
  //     console.log(' view order detail response', res)
  //     this.spinner.hide();
  //     if (res.status == 200) {
  //       this.orderDetail = res.body;
  //       this.order_items = this.orderDetail.order_items[0];
  //       //this.restaurant_address = this.orderDetail.restaurant_address;
  //     }
  //   }, error => {
  //     console.log('error', error)
  //     if (error.status == 401 || error.status == 403) {
  //       this.service.toastErr(error.error.detail)
  //     }
  //   })
  // }

  helpandSupportList() {
    this.service.showSpinner()
    let url = 'content/help-question?user=customer&help_support=true';
    this.service.hideSpinner()
    this.service.getApi(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.service.showSuccess(res.body.message)
        console.log()
        // this.generalIssues =res.body.data.results;
      }
      console.log(res)

    }, err => { 
      this.service.hideSpinner()
      this.service.toastErr(err.error.message)
    })
  }
  
  generalIssueList() {
    this.selectedItemIndex = null
    this.generalIssues = []
    this.totalItems = 0
    let url = `content/help-question?user=customer&general_issue=true&page=${this.pageNumber}`;
    this.service.showSpinner()
    this.service.getApi(url).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
        let arr = [...res.body.results];
        this.totalItems = res.body.count
        arr.forEach((item, index) => {
          item['accordionClass'] = 'shadowbox main-covering collapse multi-collapse'
        });
        this.generalIssues = [...arr]
      }
    }, err => { 
      this.spinner.hide();
      if(err.status == 400){
        this.service.toastErr(err.error.message);
      }
    })
  }

  openAccordion(item) {
    if (item['accordionClass'] == 'shadowbox main-covering collapse multi-collapse') {
      item['accordionClass'] = 'shadowbox main-covering collapse multi-collapse show';
    } else {
      item['accordionClass'] = 'shadowbox main-covering collapse multi-collapse';
    }
  }

  legalTerms(type) {
    let url
    if (type == 'terms') {
      url = 'content/terms-and-condition?user=customer&lang=en';
      this.service.showSpinner();
      this.service.getApi(url).subscribe((res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.legelTermsCondition.content = res.body.content || res.body.description;
          if (this.legelTermsCondition.content.length > 200) {
            this.legelTermsCondition.terms = this.legelTermsCondition.content.slice(0, 200)
            this.legelTermsCondition.readMore = true
          } else {
            this.legelTermsCondition.terms = this.legelTermsCondition.content
            this.legelTermsCondition.readMore = false
          }
        }
      }, err => {
        this.service.showSpinner()
       })
  
    } else if (type == 'privacy') {
      url = 'content/privacy-policy'
      this.service.showSpinner()
      this.service.getApi(url).subscribe((res: any) => {
        this.service.hideSpinner()
        if (res.status == 200) {
          this.legelPrivacyCondition.content = res.body.content || res.body.description;
          if(this.legelPrivacyCondition.content.length > 200){
            this.legelPrivacyCondition.policy = this.legelPrivacyCondition.content.slice(0,200)
            this.legelPrivacyCondition.readMore = true
          } else {
            this.legelPrivacyCondition.policy = this.legelPrivacyCondition.content
            this.legelPrivacyCondition.readMore = false
          }
        }
      }, err => { })
    } else if (type == 'refund') {
      url = 'content/refund-cancellation?lang=en'
      this.service.showSpinner()
      this.service.getApi(url).subscribe((res: any) => {
        this.service.hideSpinner()
        if (res.status == 200) {
          this.legelRefundCondition.content = res.body.content || res.body.description;
          if(this.legelRefundCondition.content.length > 200){
            this.legelRefundCondition.refund = this.legelRefundCondition.content.slice(0,200)
            this.legelRefundCondition.readMore = true
          } else {
            this.legelRefundCondition.refund = this.legelRefundCondition.content
            this.legelRefundCondition.readMore = false
          }
        }
      }, err => { 
        this.service.hideSpinner()
        this.service.toastErr(err.error.message)
      })
    }

  }

  getFaq() {
    let url = `content/faq?user=customer&lang=en/th&page=${this.pageNumber}`;
    this.service.showSpinner()
    this.service.getApi(url).subscribe((res: any) => {
      this.service.hideSpinner()
      if (res.status == 200) {
      this.faqArr = res.body.results;
      // this.service.showSuccess(res.body.message)
      }
    }, err => { 
      this.service.hideSpinner()
      this.service.toastErr(err.error.message)
    })
  }

  show(type) {
    if (type == 'more') {
      this.legelPrivacyCondition.policy = this.legelPrivacyCondition.content
      this.legelRefundCondition.refund = this.legelRefundCondition.content
      this.legelTermsCondition.terms = this.legelTermsCondition.content;
     
      this.legelTermsCondition.readMore = false

    } else if (type == 'less') {
      this.legelTermsCondition.terms = this.legelTermsCondition.content.slice(0, 200);
      this.legelTermsCondition.policy = this.legelTermsCondition.content.slice(0, 200);
      this.legelTermsCondition.refund = this.legelTermsCondition.content.slice(0, 200);
      this.generalIssues = this.generalIssues.slice(0, 200);

      this.legelTermsCondition.readMore = true
    }
  }

   //----------------------------------------to integrate Chat socket ------------------------------------\\
   openHelp(index,item) {
     this.service.showSpinner()
    this.service.getApi(`customer/order-detail/${item.id}`).subscribe(res=>{
      this.service.hideSpinner()
      if(res.status == 200){
      this.selectedOrderObj = item
      this.selectedOrderObj.chat_thread = [...res.body.chat_thread]
      this.selectedItemIndex = index
      this.getPastConversation()
      }
    })
    this.selectedItemIndex = index
    this.selectedOrderObj = item
    this.getPastConversation()
  }

  chatHelpModal(title) {
    this.active_generalIsuue_Chat = false;
    this.room_id = ''
    this.service.chatArr = [];
    console.log(this.receiverId)
    this.receiverId = (this.selectedOrderObj.order_status != 'Delivered' && this.selectedOrderObj.order_status != 'Cancelled' && this.selectedOrderObj.order_status != 'Rejected' && this.selectedOrderObj.order_status != 'Order Picked' && this.selectedOrderObj.order_status != 'Out for Delivery') ? this.selectedOrderObj.merchant_id : localStorage.getItem('support_id');
    this.chat_type = (this.receiverId == this.selectedOrderObj.merchant_id) ? 'm-c' : 'a-c';
    let room = {
    
        'sender_id': localStorage.getItem('user_id'),
        'receiver_id': this.receiverId,
        'order_id':this.selectedOrderObj.id ,
        'chat_type': this.chat_type,
        'support': title,
        "message": title,
        'message_type': 'text',
        'time': new Date().getTime()
    
    }
    // this.service.chatArr.push(room.room)
    this.chatTitle = title
    if(this.active_generalIsuue_Chat == false) {
      this.service.connectSocket(room);
    }
    this.message = title;
    this.service.socket.addEventListener('open', (event) => {
      $('#chat-modal').modal({ backdrop: 'static', keyboard: false })
    });
    // Listen for messages
    this.service.socket.addEventListener('message', (event)=> {
      console.log('Message from server ', event.data);
      let eventData = JSON.parse(event.data)
      if(eventData.id){
        this.room_id = eventData.room_id
      }
    });
  }

  chatHelpModalGeneral(title) {
    console.log('genral issue')
    this.active_generalIsuue_Chat = true
    this.room_id = ''
    this.service.chatArr = [];
    this.receiverId = localStorage.getItem('support_id')
    this.chat_type =  'a-c';
    let room = {
      'sender_id': localStorage.getItem('user_id'),
      'receiver_id': this.receiverId,
      'chat_type': this.chat_type,
      'support': title,
      "message": title,
      'message_type': 'text',
      // 'order_id':this.selectedOrderObj.id , // optional
      'time': new Date().getTime()
  
    }
  
    // this.service.chatArr.push(room.room)
    this.chatTitle = title
    if(this.active_generalIsuue_Chat == true) {
      this.service.connectSocket(room);
    }
    this.message = title
    this.service.socket.addEventListener('open', (event) => {
      $('#chat-modal').modal({ backdrop: 'static', keyboard: false })
     
    });

     // Listen for messages
     this.service.socket.addEventListener('message', (event)=> {
      console.log('Message from server ', event.data);
      let eventData = JSON.parse(event.data)
      if(eventData.id){
        this.room_id = eventData.room_id
      }
    });
  }

  backallOrder() {
    this.selectedItemIndex = null
  }


  getPastConversation() {
    this.pastThreadArr =  this.selectedOrderObj.chat_thread
    // this.pastThreadArr = []
    // let url = `customer/all-conversation-threads`;
    // this.spinner.show();
    // this.service.getApi(url).subscribe((res: any) => {
    //   this.spinner.hide();
    //   if (res.status == 200) {
    //     this.pastThreadArr =  res.body;
    //   }
    // }, err => {
    //   this.spinner.hide();
    //  })
  }

  // viewDetails(orderObj) {
  //   this.selectedOrderObj = orderObj

  // }
  viewDetails(order_id){
    this.service.getApi(`customer/order-detail/${order_id}`).subscribe(res=>{
      if(res.status == 200){
      this.selectedOrderObj = res.body
      }
    },err=>{
      if(err.status == 400){
        this.service.toastErr(err.error.message)
      }
    })
  }


  sendMessage(type) {
    let message ;
    console.log(type);
    this.room_id = this.service.chatArr.length > 0 ? this.service.chatArr[0].room_id :''
    if(type == 'text') {
      message = {
          "room_id" : this.room_id,
          "message": this.message,
          "sender_id":localStorage.getItem('user_id'),
          "receiver_id":this.receiverId, 
          "image": "",
          "message_type": "text",
          "chat_type" : this.chat_type,
          "time": new Date().getTime(),
          "action" : "send"
        
      }
     
    }else {
      message = {
          "room_id" : this.room_id,
          "message": '',
          "sender_id":localStorage.getItem('user_id'),
          "receiver_id":this.receiverId, 
          "image": this.imageUrl,
          "message_type": type,
          "chat_type" : this.chat_type,
          "time": new Date().getTime(),
          "action" : "send"
      }
    }
    if(this.active_generalIsuue_Chat == false){
      message['order_id'] = this.selectedOrderObj.id
    } 
    // this.service.chatArr.push(message.messages);
    this.message = ""
    this.service.chatArr.push(message)
    this.service.socket.send(JSON.stringify(message))
    this.automaticScroll();
  }

  automaticScroll() {
    let container = document.getElementById('chatBox');
    container.scrollTop = container.scrollHeight;
  }

  getChatHistory(item,message) {
    this.active_generalIsuue_Chat = false
    this.room_id = item.id
    this.service.chatArr = [];
    this.chatTitle = item.title;
    this.receiverId = (this.selectedOrderObj.order_status != 'Delivered' && this.selectedOrderObj.order_status != 'Cancelled' && this.selectedOrderObj.order_status != 'Rejected' && this.selectedOrderObj.order_status != 'Order Picked' && this.selectedOrderObj.order_status != 'Out for Delivery') ? this.selectedOrderObj.merchant_id : localStorage.getItem('support_id');
    this.chat_type = (this.receiverId == this.selectedOrderObj.merchant_id) ? 'm-c' : 'a-c';
    this.service.showSpinner()
    this.service.getApi(`chat/chat-history/${item.id}`).subscribe(res=>{
      this.service.hideSpinner()
      if(res.status == 200) {
        this.service.chatArr = [...res.body];
        this.service.chatArr.map(ele=>{
          ele['sender_id'] = ele.sender
          ele['receiver_id'] = ele.receiver
          ele['time']= new Date(ele.created_at).getTime()
        })
    //     let room = {
    //       'action':'join',
    //       'room': {
    //           'id':item.id
    //       }
    //  }
        let room = {
    
        'sender_id': localStorage.getItem('user_id'),
        'receiver_id': this.receiverId,
        'order_id':this.selectedOrderObj.id ,
        'chat_type': this.chat_type,
        "message": "Hi",
        'message_type': 'text',
        'time': new Date().getTime()
    
    }
        this.service.connectSocket(room);
        this.chatTitle = message
        $('#chat-modal').modal({ backdrop: 'static', keyboard: false })
        this.automaticScroll();
      }
    },err=>{
      this.service.hideSpinner()
      
    })  
  }

  //----------leave chat ----------------//
  closeChat(){
    // this.room_id = this.service.chatArr.length > 0 ? this.service.chatArr[0].room_id : this.room_id
    // let request = {
    //   'action':'leave',
    //   'room': {
    //       'id':this.room_id
    //   }
    // } 
    let request = {
      'id':this.service.room_id
    } 
    this.service.socket.send(JSON.stringify({id:this.service.room_id}))
    this.service.socket.close()
  }

  uploadImage(event) {
    console.log(event)
    if(event.target.files && event.target.files[0]) {
      if((event.target.files[0].type == 'image/png') || (event.target.files[0].type == 'image/jpg') || (event.target.files[0].type == 'image/jpeg') ||(event.target.files[0].type == 'image/gif') || (event.target.files[0].type == 'image/pdf') ) {
        let file = event.target.files[0];
        let formdata =  new FormData();
        formdata.append('image',file);
        this.spinner.show();
        this.service.postApi('merchant/image',formdata).subscribe(res=>{
          this.spinner.hide();
          if(res.status == 200){
            this.imageUrl = res.body['file_name'];
            this.sendMessage(event.target.files[0].type) 
          }
        },err=>{
          this.spinner.hide();
          this.imageUrl = '';
          this.service.toastErr(err.error.message)
        })
      }else {
        this.service.toastErr('Only jpg,jpeg,png,gif and pdf are supported!')
      }
    }
  }
  //-----------------------To navigate restaurant-------------------------------------//

  navigateToRestaurant(data) {
    localStorage.setItem('restaurantDetail',JSON.stringify(data));
    this.router.navigate(['restaurant'])
  }

  applyDiscount(){
    this.cartArr.forEach(ele => {
      this.selectedItemsArray.push({ "id": ele.id, "price": ele.total_pay * ele.quantity })
    });

    let data ={
      "restaurant_id": Number(this.rest_id),
      "item_total": this.cartSubTotal,
      "items":  this.selectedItemsArray
          }
     this.service.postApi('customer/apply-discount',data).subscribe((res: any) => {
      console.log('response on apply-discount',res);
      this.selectedItemsArray = []
     })
  }
  checkCart(){
    if(localStorage.getItem('myCart')){
      this.cartArr = JSON.parse(localStorage.getItem('myCart'));
      this.cartArr.forEach(item => {
        this.cartSubTotal = this.cartSubTotal + (item.total_pay * item.quantity)
      });
    }
  }

  // ------------------------pagination -------------------------//
  pagination(page){
    this.pageNumber=page;
    this.generalIssueList()  
  }
  
  paginate(page){
    this.pageNumber = page;
    this. getFaq()
  }


}