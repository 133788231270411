import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-restaurant2',
  templateUrl: './restaurant2.component.html',
  styleUrls: ['./restaurant2.component.css']
})
export class Restaurant2Component implements OnInit {
  id: any;
  restaurantDetail: any;
  cuisines: any = [];
  itemList: any = [];
  getRestrantItemList: any = [];
  rest_id: any;
  rest_id1: any;
  rest_detail: any = [];
  restItem: any;
  increase: any = 1;
  restItem1: any;
  constructor(private activated: ActivatedRoute,
    private service: DataserviceService,
    private router: Router,
    private cookieService: CookieService) {
    this.activated.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  ngOnInit() {
    window.scroll(0, 0)
    let url = window.location.href
    // let url ='https://fooddeliverypython.mobiloitte.io'
    this.rest_id = url.split('/')[4]
    this.rest_id1 = url.split('/')[5]
    // this.getRestaurantDetail()
    this.getRestrantItem()
    this.getItem()
  }

  async getRestaurantDetail() {

    let lat : any , lng : any
    if(!localStorage.getItem('latitude') || !localStorage.getItem('longitude')){
      let cordinates : any = await  this.service.getCurrentLocaation()
      lat = cordinates.latitude
      lng = cordinates.longitude
      localStorage.setItem('latitude',lat)
      localStorage.setItem('longitude',lng)
    }
    if(localStorage.getItem('token')){
      this.service.getApi(`customer/restaurant-detail/${this.id}?lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}`).subscribe((res: any) => {
        if (res.status == 200) {
          this.restaurantDetail = res.body
          this.cuisines = res.body.cuisines
          console.log('this.cuisines', this.cuisines)
        }
      })
    }else{
      this.service.getApi(`customer/restaurant-detail/${this.id}?lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}`).subscribe((res: any) => {
        if (res.status == 200) {
          this.restaurantDetail = res.body
          this.cuisines = res.body.cuisines
          console.log('this.cuisines', this.cuisines)
  
        }
      })
    }
    
  }

  getItem() {
    if(localStorage.getItem('token')){
      this.service.getApi('customer/item').subscribe((res: any) => {
        if (res.status == 200) {
          this.itemList = res.body.results
          console.log('this.itemList', this.itemList)
        }
      })
    }else{
      this.service.getApi('customer/item').subscribe((res: any) => {
        if (res.status == 200) {
          this.itemList = res.body.results
          console.log('this.itemList', this.itemList)
        }
      })
    }

  }

  increaseQuantity() {
    this.increase = + this.increase + 1
    console.log('increase', this.increase)
    this.getRestrantItem()
  }
  decreaseQuantity() {
    this.increase = this.increase - 1
    console.log('decrease', this.increase)

    this.getRestrantItem()
  }

  getRestrantItem() {
    if(localStorage.getItem('token')){
      this.service.getApi('customer/item?restaurant_id=' + this.rest_id).subscribe((res: any) => {
        console.log('getRestrantItemList Response', res)
        if (res.status == 200) {
          this.rest_detail = res.body.results
          this.getRestrantItemList = res.body.results[0].items
          console.log('this.getRestrantItemList', this.getRestrantItemList)
          this.getRestrantItemList.forEach(ele => {
            if (ele.id == this.rest_id1) {
              this.restItem = ele
              console.log('cookie', this.cookieService.get('itemPrize'))
              if (this.cookieService.get('itemPrize')) {
                this.restItem1 = ele.selling_price + (Number(this.cookieService.get('itemPrize')) * this.increase)
              }
              else {
                this.restItem1 = ele.selling_price + (ele.selling_price * this.increase)
              }
            }
            localStorage.setItem('totalPrize', this.restItem1)
          });
  
        }
      })
    }else{
      this.service.getApi('customer/item?restaurant_id=' + this.rest_id).subscribe((res: any) => {
        console.log('getRestrantItemList Response', res)
        if (res.status == 200) {
          this.rest_detail = res.body.results
          this.getRestrantItemList = res.body.results[0].items
          console.log('this.getRestrantItemList', this.getRestrantItemList)
          this.getRestrantItemList.forEach(ele => {
            if (ele.id == this.rest_id1) {
              this.restItem = ele
              console.log('cookie', this.cookieService.get('itemPrize'))
              if (this.cookieService.get('itemPrize')) {
                this.restItem1 = ele.selling_price + (Number(this.cookieService.get('itemPrize')) * this.increase)
              }
              else {
                this.restItem1 = ele.selling_price + (ele.selling_price * this.increase)
              }
            }
            localStorage.setItem('totalPrize', this.restItem1)
          });
  
        }
      })
    }

  }

  addItem(id) {
    this.router.navigate(['/restaurant2', this.rest_id, id])
    this.getRestrantItem()
  }
  // gotoAccount(){
  //   this.router.navigate(['/restaurant2', this.rest_id, id])
  // }


}
