import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

declare var $: any;
@Component({
  selector: 'app-restaurant',
  templateUrl: './restaurant.component.html',
  styleUrls: ['./restaurant.component.css']
})
export class RestaurantComponent implements OnInit,OnDestroy {
  search$ = new Subject();
  id: any;
  restaurantDetail: any;
  cuisines: any = [];
  itemList: any = [];
  getRestrantItemList: any = [];
  url: any;
  items: any = [];
  item_option: any = [];
  item_modifiers: any = [];
  status1: boolean = false;
  status2: boolean = false;
  price1: any;
  price2: any;
  total: any;
  pageNumber:number=1
  viewCategoryObj: any = {};
  cartArr:any = [];
  searchKey:any= '';
  reportMessage: any= '';
  vegFilter:boolean=false
  selectedObj: any = {};
  selectedCartIndex: any;
  cartSubTotal:any = 0
  // outlet_id: any;
  outletRestaurant: any =[];
  deliverystatus: any;
  item_modifierArr: any = [];
  restaurentDetails: any;
  locationId: any;
  locationOutletId: any;
  totalRecord: any=0;
  limit: any=10;
  pageSize:number=10
  itemsPerPage: number = 5
  deliveryType: any;
  pickupValue: any;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    public service: DataserviceService,
    private cookieService: CookieService,
    private spinner : NgxSpinnerService,
  ) {
    // this.activated.paramMap.subscribe(params => {
    //   this.id = params.get('id');
    //   console.log("Type=-=-=-=->", this.id);
    // });
    this.activatedRoute.queryParams.subscribe((res)=>{
      this.deliverystatus = res.value       // is_take_away
    })
    this.restaurantDetail = JSON.parse(localStorage.getItem('restaurantDetail'));

    // this.id = this.restaurantDetail.id
    // this.outlet_id = this.restaurantDetail.outlet.id
    
     
     
     this.deliveryType = localStorage.getItem('deliveryType')
  
   
    // this.activatedRoute.queryParams.subscribe((res)=>{
    //   this.deliverystatus = res.value
    //   if(res.value == 'is_take_away'){
    //     this.getRestaurantDetail()
    //   }
    //   else{
    //     this.getRestaurantDetail();
    //   }
    // })
  }

  ngOnInit() {
    this.checkCart()
    this.getRestaurantDetail()
    this.subscribeToSearch();
    // this.getItem();
    // this.orderCustomization()
  }

  checkItemInCart(data) {
    if(this.cartArr.length > 0) {
      let index = this.cartArr.findIndex(x=>x.id == data.id);
      index == -1 ? this.addItemToCart(data) : $("#allreadyAddCart").modal({ backdrop: 'static', keyboard: false })
    }else {
      this.addItemToCart(data)
    }
  }

  checkCart(){
    this.service.showSpinner();
    if(localStorage.getItem('myCart')){
      this.cartArr = JSON.parse(localStorage.getItem('myCart'));
      this.cartArr.forEach(item => {
        this.cartSubTotal = this.cartSubTotal + (item.total_pay * item.quantity)
      });
    }
    this.service.hideSpinner()
  }

  subscribeToSearch() {
    this.search$.pipe(debounceTime(2000)).subscribe(searchKey=>{
      this.searchKey = searchKey;
      if(this.searchKey.length > 0) {
        this.searchDishesApi(searchKey);
      }else {
        this.onSearchCross()
      }
    })
  }

  // orderCustomization() {
  //   this.chooseOptions = new FormGroup({
  //     'itemSize': new FormControl(''),
  //     'itemType': new FormControl('')
  //   })
  // }
  //------------------------------API to get the specific restaurant items--------------------
  getRestaurantItem() {
    this.getRestrantItemList = [];
    this.totalRecord = 0
    let url = `customer/item?restaurant_id=${this.restaurantDetail.id}&location_id=${this.restaurantDetail.outlet.id}&page_size=1000`
   this.spinner.show()
      this.service.getApi(url).subscribe((res: any) => {
        console.log('getRestrantItem response', res)
        if (res.status == 200) {
          this.spinner.hide();
          this.getRestrantItemList = res.body.results; 
          this.totalRecord = res.body.count         
          // to make the recommended on top of the list
          let recommended_index = this.getRestrantItemList.findIndex(category => category.name == 'Recommended')
          if (recommended_index != 0) {
            this.getRestrantItemList.splice(0, 0, this.getRestrantItemList[recommended_index])
            this.getRestrantItemList.splice((recommended_index + 1), 1)
          }
          if(this.getRestrantItemList.length !=0){
            this.chooseCategory(this.getRestrantItemList[0])
          }
        }
        else{
          this.spinner.hide();
        }
      },(error)=>{
        this.spinner.hide();
      })
  }

  // to select category inside restuarnt
  chooseCategory(category) {
    if(category.name != 'Search Results') {
      this.searchKey= ''
    }
    this.viewCategoryObj = category;
    console.log('viewCategoryObj', this.viewCategoryObj);
    
    this.viewCategoryObj.items.map(item=>item['show']= true);
    this.vegFilter = false;
    this.viewCategoryObj.item_count = this.viewCategoryObj.items.length

  }
  // to onSearchCross
  onSearchCross(){
    this.searchKey=''
    let index = this.getRestrantItemList.findIndex(category=>category.name == 'Search Results')
    this.getRestrantItemList.splice(index,1);
    this.chooseCategory(this.getRestrantItemList[0])
  }
  

  addItemToCart(data) {
    this.selectedObj ={...data}
    if(localStorage.getItem('myCart')) {
      let myCart = JSON.parse(localStorage.getItem('myCart'));
      if(myCart.length > 0 ) {
        let index = myCart.findIndex(item=>item.restaurant_id == data.restaurant)
        if(index == -1) {
          $("#CartRefresh").modal({ backdrop: 'static', keyboard: false })
        }else {
          this.manageCart(data)
        }
      }else {
        this.manageCart(data)
      }
    }else {
      this.manageCart(data)
    }
  }

  manageCart(data) {
    this.selectedObj = {...data};
    if(this.selectedObj.option == true){
      this.selectedObj.item_option.map(item =>{item['selected']=false})
    }
    if(this.selectedObj.modifiers == true){
      this.selectedObj.item_modifiers.map(item =>{item['selected']=false})
    }
    this.selectedObj['total_pay']=data.selling_price
    if(data.option == true || data.modifiers == true)  {
      this.item_modifierArr= []
      $("#Customize1").modal({ backdrop: 'static', keyboard: false })
    }else {
      this.addToCart(this.selectedObj)
    }
  }

  selectedSize(price, val) {
    console.log('event', price, val);
    if (val == true) {
      this.price1 = price;
      console.log('this.price1', this.price1);
    }
  }

  selectedItem(price, val) {
    console.log('event', price, val);
    if (val == true) {
      this.price2 = price;
      console.log('this.price2', this.price2);
    }
    this.total = this.price1 + this.price2
    this.cookieService.set('itemPrize', this.total);
  }

  // addModalItem(id) {
  //   console.log('id', id)
  //   $("#Customize1").modal('hide')
  //   this.router.navigate(['restaurant2', this.id, id])
  // }

  like() {
    if(!localStorage.getItem('token')) {
      this.service.showErrToast('Please login first!')
      // this.router.navigate(['']);
      return;
    }
    var data = {
      "w_restaurant": [Number(this.id)]
    }
    this.service.postApi('customer/favorite-restaurant', data).subscribe((res: any) => {
      console.log('like response', res)
      if (res.status == 201) {
        this.restaurantDetail.is_favorite = true;
        this.service.showSuccess(res.body.message)
      }
    }, error => {
      console.log('error', error)
      if (error.status == 403 || error.status == 400) {
        this.service.toastErr(error.error.message)
      }else if(error.status == 401){
        this.service.toastErr(error.error.detail)
      }
    })
  }

  unlike() {
    
    var data = {
      "w_restaurant": [Number(this.id)]
    }
    this.service.putApi('customer/favorite-restaurant', data).subscribe((res) => {
      if (res.status == 200) {
        this.restaurantDetail.is_favorite = false
        this.service.showSuccess(res.body.message)
      }
    }, error => {
      console.log('error', error)
      if (error.status == 400) {
        this.service.toastErr(error.error.message)
      } else if(error.status == 401){
        this.service.toastErr(error.error.detail)
      }else if(error.status == 403){
        this.service.toastErr(error.error.detail)
        this.router.navigate([''])
      }
    })
  }

  blockRestaurant() {
    if(!localStorage.getItem('token')) {
      this.service.toastErr('Please login first!')
      this.router.navigate(['']);
      return;
    }
    var data = {
      "restaurant": [Number(this.id)]
    }
    this.service.postApi('customer/block', data).subscribe((res: any) => {
      if (res.status == 201) {
        // this.router.navigate(['/nearyou'])
        this.router.navigate(['/nearyou'])
        this.service.showSuccess(res.body.message)
      }
    }, error => {
      console.log('error', error)
      if (error.status == 400) {
        this.service.toastErr(error.error.message)
      }else if(error.status == 403){
        this.service.toastErr(error.error.detail)
        this.router.navigate([''])
      }
    })
  }


  reportSubmit(){
    if(!localStorage.getItem('token')) {
      this.service.toastErr('Please login first!')
      this.router.navigate(['']);
      return;
    }
    if(this.reportMessage.length == 0 ){
      this.service.toastErr("Please Enter Report Resson!")
      return;
    }
    let data ={
      "restaurant":this.id,
      "remark":this.reportMessage
    } 
    this.service.postApi('customer/report-restaurant',data).subscribe((res:any) =>{
      if(res.status == 200){
        this.service.showSuccess(res.body.message)

      }

    },err =>{
      console.log(err)
      if (err.status == 400) {
        this.service.toastErr(err.error.remark)
       
      }else if(err.status == 403){
        this.service.toastErr(err.error.detail)
        this.router.navigate([''])
      }
     
    })
  }

  selectOutlet(item){
    this.restaurantDetail.outlet.id = item.id
    this.getRestaurantDetail() 
  }

  //----------------------------- To get restaurant detail--------------------------------------
  async getRestaurantDetail() {

    let lat : any , lng : any
    if(!localStorage.getItem('latitude') || !localStorage.getItem('longitude')){
      let cordinates : any = await  this.service.getCurrentLocaation()
      lat = cordinates.latitude
      lng = cordinates.longitude
      localStorage.setItem('latitude',lat)
      localStorage.setItem('longitude',lng)
    }
    let url = `customer/restaurant/${this.restaurantDetail.id}/outlet/${this.restaurantDetail.outlet.id}?lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}`
    console.log('------->', this.restaurantDetail.id)
    
    this.service.getApi(url).subscribe((res: any) => {
      //console.log('------->',res);
      if (res.status == 200) {
        this.restaurantDetail = res.body;
        localStorage.setItem('restaurantDetail',JSON.stringify(res.body));
        localStorage.setItem('restaurantDetailId',JSON.stringify(res.body.id));
        this.getRestaurantItem()
        this.changeOutlet();

      }
    },err=>{
      if(err.status == 200){
        this.service.toastErr(err.error.message)
      }
    })
  }

  //-------------------------- to get searchDish detail------------------------------------------//


  searchDishesApi(searchKey){
    this.service.showSpinner()
    let url = `customer/item-search-filter?restaurant=${this.id}&search=${searchKey}`

    this.service.getApi(url).subscribe((res:any) =>{
      if(res.status == 200){
      let categoryObj = { 
        "id": '',
        "name": "Search Results",
        "item_count": res.body.results.length,
        "items": res.body.results,
      }
      this.service.hideSpinner();
      if(this.getRestrantItemList[0].name == 'Search Results') {
        this.getRestrantItemList[0] = categoryObj
      }else {
        this.getRestrantItemList.splice(0,0,categoryObj);
      }
      this.chooseCategory(categoryObj)
      }
    })
  }


  //---------------------------------------to get apply veg filter-----------------------------------//
  applyVegFilter(){
    this.vegFilter = !this.vegFilter 
    this.viewCategoryObj.item_count = 0
    if(this.vegFilter  == true) {
      this.viewCategoryObj.items.forEach(item => {
        if(item.food_type != 'Veg'){
            item['show'] = false;
        }else {
          this.viewCategoryObj.item_count =  this.viewCategoryObj.item_count + 1
        }
      });
    }else {
      this.viewCategoryObj.items.forEach(item => {
        item.show = true;
        this.viewCategoryObj.item_count =  this.viewCategoryObj.item_count + 1
      });
    }
  }

  calculateTotalPay(item,event,index,type){
   // Manage one checkbox will select at a time

   if (type  == 'option'){
    this.selectedObj.total_pay = this.selectedObj.selling_price
    if(this.selectedObj.option == true) {
      if(event == true){
        this.selectedObj.item_option.forEach((x)=>{
          if (x.id !==item.id){
            x['is_disabled']= true;
          }
        })
      }
      else {
        this.selectedObj.item_option.forEach((x)=>{
            x['is_disabled']= false;        
        })
      }

      this.selectedObj.item_option.forEach(item => {
        if(item.selected == true){
          this.selectedObj.total_pay = 0
          this.selectedObj.total_pay= this.selectedObj.total_pay + item.selling_price ;
          console.log('total pay', this.selectedObj.total_pay);
          console.log('item.selling_price',item.selling_price);
        }
      });  
    }
  
    // modifier with type option 

    this.selectedObj.item_modifiers.forEach(item => {
      if (item.selected == true) {
        this.selectedObj.total_pay = this.selectedObj.total_pay + item.selling_price;
      }
    });
  }

    
    // Modifiers logic start here
    if (type == 'modifiers'){
      if(this.selectedObj.modifiers == true) {
        console.log('selectedObj modifiers',this.selectedObj.item_modifiers);
        if (item.selected == true) {
          this.selectedObj.total_pay = this.selectedObj.total_pay + item.selling_price;
        }
        else{
          this.selectedObj.total_pay = this.selectedObj.total_pay - item.selling_price;
        }
        if (item.selected == true) {
          this.item_modifierArr.splice(index,0,item.id)       
        }
        else if (item.selected == false) {
        this.item_modifierArr.splice(index, 1);
        }
        console.log('item_modifierArr',this.item_modifierArr);
      }
    }
    


           // Modifiers logic end here         
  }



  addToCart(data) {
    data['quantity'] = 1;
    this.cartSubTotal = this.cartSubTotal + data.total_pay;
    data['restaurant_name'] = this.restaurantDetail.restaurant_name;
    data['restaurant_image'] = this.restaurantDetail.restaurant_image 
    data['restaurant_id'] = this.restaurantDetail.id;
    data['selected_modifiers'] =  this.item_modifierArr;
    this.cartArr = localStorage.getItem('myCart') ? [...JSON.parse(localStorage.getItem('myCart'))]: []
    this.cartArr.push(data)
    localStorage.setItem('myCart',JSON.stringify(this.cartArr))
    this.service.cartLength.next(this.cartArr.length) 

    
    
  }

  startFreshYes() {
    localStorage.removeItem('myCart');
    this.cartSubTotal = 0
    this.addItemToCart(this.selectedObj)
  }


  ngOnDestroy(){
    
    localStorage.setItem('myCart',JSON.stringify(this.cartArr))
  }

  //---------------------------------------------------decrease Quantity ----------------------------------//

  decreaseQuantity(item,index){
    this.cartSubTotal = this.cartSubTotal - this.cartArr[index].total_pay
    if(item.quantity == 1 ){
      this.cartArr.splice(index,1);
    }else{
      this.cartArr[index].quantity = item.quantity - 1; 
    }
    localStorage.setItem('myCart',JSON.stringify(this.cartArr))
    this.service.discount.next(this.cartArr)
    this.service.cartLength.next(this.cartArr.length)

  }
  increaseQuantity(item,index){
    this.selectedCartIndex = index
    if(item.modifiers == true || item.option == true){
      $("#CartModal").modal({ backdrop: 'static', keyboard: false })
    }else {
      this.cartArr[index].quantity = item.quantity + 1;
    }
    localStorage.setItem('myCart',JSON.stringify(this.cartArr));
    if ( this.cartArr.length > 0){
      this.cartSubTotal = 0
      this.cartArr.map((res,i)=>{
        this.cartSubTotal = this.cartSubTotal + res.quantity*res.total_pay  
        
      })
    }
    this.service.discount.next(this.cartArr)
    this.service.cartLength.next(this.cartArr.length)

  }

  repeatOrder(){
    this.cartArr[this.selectedCartIndex].quantity = this.cartArr[this.selectedCartIndex].quantity + 1;
    this.cartSubTotal = this.cartSubTotal + this.cartArr[this.selectedCartIndex].total_pay;
    localStorage.setItem('myCart',JSON.stringify(this.cartArr))
    this.service.discount.next(this.cartArr)
    this.service.cartLength.next(this.cartArr.length)

  }

  //------------------------change Outlet -----------------------------------//
 async changeOutlet(){
    let lat : any , lng : any
    if(!localStorage.getItem('latitude') || !localStorage.getItem('longitude')){
      let cordinates : any = await  this.service.getCurrentLocaation()
      lat = cordinates.latitude
      lng = cordinates.longitude
      localStorage.setItem('latitude',lat)
      localStorage.setItem('longitude',lng)
    }
    this.restaurantDetail = JSON.parse(localStorage.getItem('restaurantDetail'));
    this.id = this.restaurantDetail.id
    var data = { 
    "latitude":localStorage.getItem('latitude'),
    "longitude":localStorage.getItem('longitude'),
    "id":this.id,
    "outlet_id": this.restaurantDetail.outlet.id
    }
    
    this.outletRestaurant = []
    this.service.postApi(`customer/get-resturant-branch`,data).subscribe(res=>{
      if(res.status == 200){
        this.outletRestaurant = res.body;
        this.restaurentDetails  = localStorage.getItem('restaurantDetail') ? JSON.parse(localStorage.getItem('restaurantDetail')): [];
        localStorage.setItem('locationId',JSON.stringify(this.restaurantDetail.outlet.id))
      }

    },err=>{
      if(err.ststus == 400){
        this.service.toastErr(err.error.message)
      }else if(err.status == 403){
        this.service.toastErr(err.error.detail)
        this.router.navigate([''])
      }
    })
  }

  // ------------------------pagination -------------------------//
  pagination(page){
    this.pageNumber=page;
    this.getRestaurantItem()   
    console.log()
  }

}
