import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Header1Component } from '../header1/header1.component';
import { NearyouComponent } from '../nearyou/nearyou.component';
declare var $: any;
@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: ['./sidemenu.component.css']
})
export class SidemenuComponent implements OnInit {
    lang: any;
    restaurantList: any = [];
    length: any;
    pages: number = 1;
    page: number = 10;
    rest: any;
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  locationData: string;
  addressStatus: string;
  geocoder: google.maps.Geocoder;
  myLocation: any;
  lat: any;
  lng: any;
  latitude: number;
  longitude: number;
  zoom: number;
  options: any[];
  location: any;
  recentAddress: any = [];
  myRecentAddress: any=[];
    
   //-------------------- Manage address from dropdown --------------------------------------------
   

    constructor(public router: Router, 
      public service: DataserviceService,
      private header : Header1Component,
      private nearYou : NearyouComponent,
       public spinner:NgxSpinnerService) { }

    ngOnInit() {
      this.myRecentAddress = localStorage.getItem('recentAddress') ? JSON.parse(localStorage.getItem('recentAddress')) : [];
      console.log('My recent address', this.myRecentAddress);
    }

    public handleAddressChange(address: Address) {
      this.options = [];
      localStorage.setItem('address',address.formatted_address)
      console.log(address.geometry.location.lng());
      
      this.lat = address.geometry.location.lng()
      this.lng = address.geometry.location.lat()
      localStorage.setItem('latitude', JSON.stringify(this.lng))
      localStorage.setItem('longitude', JSON.stringify(this.lat));
      this.recentAddress.unshift(address);
      if (this.myRecentAddress.length !=0){
        this.recentAddress = this.myRecentAddress;
        this.recentAddress.unshift(address);
      }
      localStorage.setItem('recentAddress', JSON.stringify(this.recentAddress));
      this.myRecentAddress = JSON.parse(localStorage.getItem('recentAddress'));
      const pos ={
        lat : localStorage.getItem('latitude'),
        lng  : localStorage.getItem('longitude')
      };
      this.service.locationUpdate.next(JSON.stringify(pos))
      let getAddress =  localStorage.getItem('address')
      this.service.profileData.next(getAddress)
      this.nearYou.getRestaurant();
      $('#location-modal').modal('hide')
      this.router.navigate(['nearyou'])
    }

    locate() {
      if (!navigator.onLine) {
        this.service.toastErr('You are offline!')
        return;
      }
  
      if (!navigator.geolocation) {
        this.service.toastErr('Geolocation is not supported by this browser.');
        return;
      }
  
      navigator.geolocation.getCurrentPosition(position => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;        
        localStorage.setItem('latitude', JSON.stringify(this.lat))
        localStorage.setItem('longitude', JSON.stringify(this.lng))
        this.service.getLocation(environment.mapUrl + `${localStorage.getItem('latitude')},${localStorage.getItem('longitude')}`).subscribe(res => {
          console.log(res.status)
          if (res.status == 'OK') {
            localStorage.setItem('address', res.results[1].formatted_address)
            this.service.location = res.results[1].formatted_address;
            this.header.service.location;
            this.service.locationUpdate.next('')
            this.nearYou.getRestaurant()
            this.router.navigate(['nearyou'])
          }
        }, err => {
          this.spinner.hide();
          console.log(err)
        })
      })
    }

}
