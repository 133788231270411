import { Component, OnInit, ViewChild } from "@angular/core";
import { DataserviceService } from "src/app/Service/dataservice.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
  FormArray,
} from "@angular/forms";
import { Header1Component } from "../header1/header1.component";
import { NgxSpinnerService } from "ngx-spinner";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Address } from "ngx-google-places-autocomplete/objects/address";
import { DatePipe } from "@angular/common";
declare var $: any;
declare var google: any;
@Component({
  selector: "app-account1",
  templateUrl: "./account1.component.html",
  styleUrls: ["./account1.component.css"],
})
export class Account1Component implements OnInit {
  @ViewChild("placesRef", { static: true }) placesRef: GooglePlaceDirective;
  selectedTab: any;
  zoom: number = 8;
  lat: any;
  lng: any;
  map: google.maps.Map;
  infoWindow: google.maps.InfoWindow;
  getRestrantItemList: any = [];
  rest_id: string;
  rest_id1: any;
  restItem: any;
  profile: any;
  totalPrize: any;
  increase: any = 1;
  restItem1: any;
  loginMessage: any;
  isValidNumber: any;
  myCode: any = "+91";
  loginForm: FormGroup;
  signupForm: FormGroup;
  otpForm: FormGroup;
  loginMessage1: any;
  uuid: any;
  mobile: any;
  cartArr: any = [];
  cartSubTotal: any = 0;
  selectedCartIndex: any;
  selectedObj: any = {};
  firstName: string;
  token: string;
  timer: any = 60;
  x: any;
  getTax: any = 0;
  signUpMessage: any;
  cell: any;
  getDeliveryCharge: any = 0;
  couponCode: any;
  pos: { lat: number; lng: number };
  options: any[];
  myAddress: string;
  addAddressForm: FormGroup;
  AddressType: any;
  getDeliveryAddress: any;
  addNewAddressForm: FormGroup;
  order_Items: any = [];
  public formDummyData = [
    {
      addressName: "",
    },
  ];
  offerId: any;
  getAllAddress: any = [];
  getAllDeliverySlot: any = [];
  deliverySlot: FormGroup;
  userDeliverAddress: any;
  is_schedule: boolean = false;
  paymentMethod: any = "COD";
  id: any;
  outletID: void;
  restaurentDetails: any;
  outletId: any;
  selectedItemsArray: any = [];
  cartArrForDiscount: any = [];
  paymentCardForm: FormGroup;
  deliverAddressId: any;
  delivery_time: any;
  discount: any = 0;
  suggession: any;
  restaurantImage: any;
  modifiersId: any = [];
  item_modifierArr: any;
  delivery_date_time: any;
  Isschedule: boolean = false;
  appliedCouponDiscount: any;
  CountryCodeItems: any = [];
  adddress_id: any;
  getCartListArr: any = [];
  cardId: any;

  constructor(
    public service: DataserviceService,
    private activated: ActivatedRoute,
    private header: Header1Component,
    private router: Router,
    public datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder
  ) {
    // this.activated.paramMap.subscribe(params => {
    //   this.id = params.get('id');
    //   console.log('Id is here', this.id);

    // });

    this.cartArr = localStorage.getItem("myCart")
      ? JSON.parse(localStorage.getItem("myCart"))
      : [];
    this.restaurentDetails = localStorage.getItem("restaurantDetail")
      ? JSON.parse(localStorage.getItem("restaurantDetail"))
      : [];
    console.log(this.cartArr.length);
    if (this.cartArr.length > 0) {
      this.rest_id = this.cartArr[0].restaurant_id
        ? this.cartArr[0].restaurant_id
        : localStorage.getItem("restaurantDetailId");
      this.outletId = this.cartArr[this.cartArr.length - 1].outlet_id
        ? this.cartArr[this.cartArr.length - 1].outlet_id
        : null;

      this.restaurantImage = this.restaurentDetails.restaurant_image;
    }
    if (Object.keys(this.restaurentDetails).length != 0) {
      console.log("restaurentDetails", this.restaurentDetails);
      this.outletId = this.restaurentDetails.outlet.id;
    }
    this.service.discount.next(this.cartArr);
    //  this.applyDiscountOnItem()
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    if(!localStorage.getItem("address")){
      this.getAdd()
    }
    this.initMap();
    this.phoneCheckCountry();
    this.CountryCode();
    this.token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";

    if (localStorage.getItem("token")) {
      this.selectedTab = "choosePaymentMethod";
      this.selectedTab = "afterLogin";
      this.changeTab(this.selectedTab, "");

      this.myAddress = localStorage.getItem("address");
    } else {
      this.phoneCheckCountry();
      this.selectedTab = "beforelogin";
      // this.applyDiscountOnItem();
      this.changeTab(this.selectedTab, "");

      console.log("beforelogin");
    }

    // this.token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    // let url=window.location.href
    // this.rest_id=url.split('/')[4];
    console.log(" this.rest_id", this.rest_id, "this.rest_id1", this.rest_id1);
    this.addAddressFormValidation();
    this.paymentCardFormValidation();

    // this.addnewDuplicateFormValidation();
    this.getRestrantItem();
    this.checkCart();
    this.getRestaurantItem1();
    this.applyDiscountOnItem();
    this.getTaxDetails();
    this.getDeliveryCharges();
    this.restItem1 = Number(localStorage.getItem("totalPrize"));
    this.signUp();
    this.logedIn();
    // this.deliverHereApi()
    this.otp();
    if (this.token) {
      this.getAlladdedAddress();
      this.viewProfile();
    }

  }

  async getAdd(){
    try {
      let lat : any = localStorage.getItem('latitude') , lng : any =  localStorage.getItem('longitude')
      if(!localStorage.getItem('latitude') || !localStorage.getItem('longitude')){
        let cordinates : any = await  this.service.getCurrentLocaation()
        lat = cordinates.latitude
        lng = cordinates.longitude
        localStorage.setItem('latitude',lat)
        localStorage.setItem('longitude',lng)
      }
      let result = await this.service.getAddress( lat,lng).toPromise()
      if (result.results.length) {
        localStorage.setItem("address",result.results[0].formatted_address)
      }
      
    } catch (error) {
      console.error(error);
      
    }
  }

  //-------------------- Manage address from dropdown --------------------------------------------
  public handleAddressChange(address: Address) {
    this.options = [];
    localStorage.setItem("address", address.formatted_address);
    this.lat = address.geometry.location.lng();
    this.lng = address.geometry.location.lat();
    localStorage.setItem("latitude", JSON.stringify(this.lat));
    localStorage.setItem("longitude", JSON.stringify(this.lng));
    this.options.push(address.formatted_address);
    console.log("adress");
  }

  checkCart() {
    if (localStorage.getItem("myCart")) {
      this.cartArr = JSON.parse(localStorage.getItem("myCart"));
      this.cartArr.forEach((item) => {
        this.cartSubTotal = this.cartSubTotal + item.total_pay * item.quantity;
      });
    }
  }

  decreaseQuantity(item, index) {
    this.cartSubTotal = this.cartSubTotal - this.cartArr[index].total_pay;
    console.log("cartArray", this.cartArr);
    console.log("cartSubTotal", this.cartSubTotal);
    this.getDeliveryCharges();
    if (item.quantity == 1) {
      this.cartArr.splice(index, 1);
      if (this.cartArr.length == 0) {
        this.router.navigate(["cart"]);
        this.service.cartLength.next("0");
      }
    } else {
      this.cartArr[index].quantity = item.quantity - 1;
    }
    localStorage.setItem("myCart", JSON.stringify(this.cartArr));
    this.service.discount.next(this.cartArr);
    this.applyDiscountOnItem();
  }
  increaseQuantity(item, index) {
    this.selectedCartIndex = index;
    if (item.modifiers == true || item.option == true) {
      $("#CartModal").modal({ backdrop: "static", keyboard: false });
    } else {
      this.cartArr[index].quantity = item.quantity + 1;
      // this.cartSubTotal =  this.cartArr[index].quantity * this.cartArr[index].total_pay
    }
    localStorage.setItem("myCart", JSON.stringify(this.cartArr));

    if (this.cartArr.length > 0) {
      this.cartSubTotal = 0;
      this.cartArr.map((res, i) => {
        this.cartSubTotal = this.cartSubTotal + res.quantity * res.total_pay;
      });
      this.getDeliveryCharges();
      console.log(" this.cartSubTotal", this.cartSubTotal);
    }
    this.service.discount.next(this.cartArr);
    this.applyDiscountOnItem();
  }

  repeatOrder() {
    this.cartArr[this.selectedCartIndex].quantity =
      this.cartArr[this.selectedCartIndex].quantity + 1;
    this.cartSubTotal =
      this.cartSubTotal + this.cartArr[this.selectedCartIndex].total_pay;
    localStorage.setItem("myCart", JSON.stringify(this.cartArr));
    this.service.discount.next(this.cartArr);
    this.applyDiscountOnItem();
  }

  checkItemInCart(data) {
    if (this.cartArr.length > 0) {
      let index = this.cartArr.findIndex((x) => x.id == data.id);
      index == -1
        ? this.addItemToCart(data)
        : $("#allreadyAddCart").modal({ backdrop: "static", keyboard: false });
    } else {
      this.addItemToCart(data);
    }
  }

  addItemToCart(data) {
    this.selectedObj = { ...data };
    if (this.selectedObj.option == true) {
      this.selectedObj.item_option.map((item) => {
        item["selected"] = false;
      });
    }
    if (this.selectedObj.modifiers == true) {
      this.selectedObj.item_modifiers.map((item) => {
        item["selected"] = false;
      });
    }
    this.selectedObj["total_pay"] = data.selling_price;
    if (data.option == true || data.modifiers == true) {
      $("#Customize1").modal({ backdrop: "static", keyboard: false });
    } else {
      this.addToCart(this.selectedObj);
    }

    // this.items.forEach(ele => {
    //   if (ele.id == id) {
    //     this.item_option = ele.item_option
    //     this.item_modifiers = ele.item_modifiers
    //   }
    // });
  }
  calculateTotalPay(item, event, index, type) {
    // Manage one checkbox will select at a time

    if (type == "option") {
      this.selectedObj.total_pay = this.selectedObj.selling_price;
      if (this.selectedObj.option == true) {
        if (event == true) {
          this.selectedObj.item_option.forEach((x) => {
            if (x.id !== item.id) {
              x["is_disabled"] = true;
            }
          });
        } else {
          this.selectedObj.item_option.forEach((x) => {
            x["is_disabled"] = false;
          });
        }

        this.selectedObj.item_option.forEach((item) => {
          if (item.selected == true) {
            this.selectedObj.total_pay = 0;
            this.selectedObj.total_pay =
              this.selectedObj.total_pay + item.selling_price;
            console.log("total pay", this.selectedObj.total_pay);
            console.log("item.selling_price", item.selling_price);
          }
        });
      }

      // modifier with type option

      this.selectedObj.item_modifiers.forEach((item) => {
        if (item.selected == true) {
          this.selectedObj.total_pay =
            this.selectedObj.total_pay + item.selling_price;
        }
      });
    }

    // Modifiers logic start here
    if (type == "modifiers") {
      if (this.selectedObj.modifiers == true) {
        console.log("selectedObj modifiers", this.selectedObj.item_modifiers);
        if (item.selected == true) {
          this.selectedObj.total_pay =
            this.selectedObj.total_pay + item.selling_price;
        } else {
          this.selectedObj.total_pay =
            this.selectedObj.total_pay - item.selling_price;
        }
        if (item.selected == true) {
          this.item_modifierArr.splice(index, 0, item.id);
        } else if (item.selected == false) {
          this.item_modifierArr.splice(index, 1);
        }
        console.log("item_modifierArr", this.item_modifierArr);
      }
    }
    // Modifiers logic end here
  }

  addToCart(data) {
    data["quantity"] = 1;
    data["selected_modifiers"] = this.item_modifierArr;
    this.cartSubTotal = this.cartSubTotal + data.total_pay;
    this.cartArr.push(data);
    localStorage.setItem("myCart", JSON.stringify(this.cartArr));
    this.service.discount.next(this.cartArr);
    this.service.cartLength.next(this.cartArr.length);

    this.applyDiscountOnItem();
  }

  viewProfile() {
    this.service.showSpinner();
    this.service.getApi("customer/view-edit-profile").subscribe(
      (res: any) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          this.profile = res.body;
          this.service.profile1.next(this.profile.first_name);
          // this.service.showSuccess(res.body.message)
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err.status == 400) {
          // this.service.toastErr(err.error.message)
        } else if (err.status == 401) {
          // this.service.toastErr(err.error.message)
        } else if (err.ststus == 403) {
          this.service.toastErr(err.error.detail);
        }
      }
    );
  }

  getTaxDetails() {
    this.service.getApi("api/tax").subscribe((res: any) => {
      if (res.status == 200) {
        this.getTax = res.body.tax_percentage;
      }
    });
  }

  getDeliveryCharges() {
    this.service.getApi("api/delivery-charges").subscribe((res: any) => {
      if (res.status == 200) {
        let charges = [];
        charges = res.body.charges;
        for (var i = 0; i <= charges.length; i++) {
          console.log("charges[0].upto", charges[0].upto);
          console.log("cartSubTotal", this.cartSubTotal);

          if (
            charges[0].upto >= this.cartSubTotal &&
            !(this.cartSubTotal <= res.body.free_delivery_upto)
          ) {
            this.getDeliveryCharge = charges[0].delivery_charges;
            break;
          } else if (
            charges[1].upto >= this.cartSubTotal &&
            this.cartSubTotal > charges[0].upto
          ) {
            this.getDeliveryCharge = charges[1].delivery_charges;
            break;
          } else if (charges[1].upto < this.cartSubTotal) {
            this.getDeliveryCharge = charges[1].delivery_charges;
          } else if (this.cartSubTotal <= res.body.free_delivery_upto) {
            this.getDeliveryCharge = 0;
          }
        }
        console.log("this.getDeliveryCharge===>304", this.getDeliveryCharge);
      }
    });
  }

  // open Coupon Modal

  openCouponModal() {
    $("#coupon-modal").modal("show");
  }

  // Apply coupon api integration start here
  applyCoupon() {
    let data = {
      restaurant_id: Number(this.rest_id),
      coupon_code: this.couponCode,
      item_total: this.cartSubTotal,
    };
    console.log("applyCoupon", data);
    this.service.postApi("customer/apply-coupon-code", data).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.service.showSuccess(res.body.message);
          $("#coupon-modal").modal("hide");
          this.couponCode = "";
          this.service.showSuccess("Coupon apply successfully");
          this.discount = res.body.item_total_difference;
          this.appliedCouponDiscount = res.body.item_total_difference;
        }
      },
      (err) => {
        console.log("error", err);
        if (err.status == 404) {
          this.service.toastErr(err.error.message);
        }
      }
    );
  }

  // Apply discount on item
  applyDiscountOnItem() {
    this.service.discount.subscribe((res) => {
      this.cartArrForDiscount = res;
    });
    console.log(
      " this.cartArrForDiscount",
      this.cartArrForDiscount,
      "offer for discount",
      this.restaurentDetails.offer
    );

    if (
      Object.keys(this.cartArrForDiscount).length != 0 &&
      this.restaurentDetails.offer != "" &&
      this.restaurentDetails.offer != null &&
      this.restaurentDetails.offer != undefined
    ) {
      this.selectedItemsArray = [];
      this.cartArrForDiscount.forEach((ele) => {
        this.selectedItemsArray.push({
          id: ele.id,
          price: ele.total_pay * ele.quantity,
        });
      });
      console.log(" this.cartArrForDiscount", this.cartArrForDiscount);
      console.log(" selectedItemsArray", this.selectedItemsArray);

      let data = {
        restaurant_id: Number(this.rest_id),
        item_total: this.cartSubTotal,
        items: this.selectedItemsArray,
      };
      console.log("applyCoupon", data);
      this.service.showSpinner();
      this.service.postApi("customer/apply-discount", data).subscribe(
        (res: any) => {
          console.log("response on apply-discount", res);
          this.selectedItemsArray = [];
          console.log(" this.selectedItemsArray ", this.selectedItemsArray);

          this.service.hideSpinner();
          if (res.status == 200) {
            this.discount = res.body.item_total_difference;
            this.offerId = res.body.offer_id;
          }
        },
        (err) => {
          this.selectedItemsArray = [];
          this.service.hideSpinner();
          if (err.status == 400) {
            this.discount = err.error.item_total_difference;
          }
        }
      );
    }
  }

  getRestrantItem() {
    this.service
      .getApi("customer/item?restaurant_id=" + this.rest_id)
      .subscribe((res: any) => {
        if (res.status == 200) {
          this.getRestrantItemList =
            res.body.results.length > 0 ? res.body.results[0].items : [];
          console.log("this.getRestrantItemList", this.getRestrantItemList);
          this.getRestrantItemList.forEach((ele) => {
            if (ele.id == this.rest_id1) {
              this.restItem = ele;
              //  console.log('cookie', this.cookieService.get('itemPrize'))
              if (localStorage.getItem("totalPrize")) {
                this.restItem1 =
                  ele.selling_price +
                  Number(localStorage.getItem("totalPrize")) * this.increase;
              } else {
                this.restItem1 =
                  ele.selling_price + ele.selling_price * this.increase;
              }
            }
          });
        }
      });
  }

  getRestaurantItem1() {
    this.service
      .getApi("customer/item?restaurant_id=" + this.rest_id)
      .subscribe((res: any) => {
        console.log("getRestrantItem response", res);
        if (res.status == 200) {
          this.getRestrantItemList = res.body.results;
          // to make the recommended on top of the list
          let recommended_index = this.getRestrantItemList.findIndex(
            (category) => category.name == "Recommended"
          );
          if (recommended_index != 0) {
            this.getRestrantItemList.splice(
              0,
              0,
              this.getRestrantItemList[recommended_index]
            );
            this.getRestrantItemList.splice(recommended_index + 1, 1);
          }
          console.log(this.getRestrantItemList[0]);
        }
      });
  }

  logedIn() {
    this.loginForm = this.formBuilder.group({
      phone: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[1-9][0-9]{9,13}$/),
        ])
      ),
      customer_pcode: new FormControl("+91", Validators.required),
    });
  }

  signUp() {
    this.signupForm = this.formBuilder.group({
      phoneNo: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^[1-9][0-9]{9,13}$/),
        ])
      ),
      name: ["", Validators.required],
      terms: ["", Validators.required],
      policy: ["", Validators.required],
      customer_signup_pcode: new FormControl("+91"),
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/
          ),
        ])
      ),
      password: new FormControl(
        "",
        Validators.compose([
          ,
          Validators.required,
          Validators.pattern(
            /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/
          ),
        ])
      ),
    });
  }

  addAddressFormValidation() {
    this.addAddressForm = this.formBuilder.group({
      flatNo: new FormControl("", Validators.required),
      landmark: new FormControl("", Validators.required),
      //"deliveryAddress" : new FormControl('',Validators.required)
    });
    // this.addAddressForm.valueChanges.subscribe((res)=>{
    //   console.log('response',res);
    // })
  }

  addnewDuplicateFormValidation() {
    this.addNewAddressForm = this.formBuilder.group({
      addressName: [""],
      address: this.formBuilder.array([]),
    });
  }

  addressType(type) {
    console.log("work type", type);
    this.AddressType = type;
  }

  async addNewAddresFun() {
    let data: any = {
      house: this.addAddressForm.value.flatNo,
      landmark: this.addAddressForm.value.landmark,
      location: this.myAddress, // this.addAddressForm.value.area ,
      address_type: this.AddressType,
      is_delivery_address: false,
    };
    let address = this.myAddress;
    try {
      let result = await this.service.getLatLong(address).toPromise();
      console.log(result.results);

      if (result.results.length) {
        data.latitude = result.results[0].geometry.location.lat;
        data.longitude = result.results[0].geometry.location.lng;
      } else {
        return this.service.toastErr("Please Enter valid address.");
      }
    } catch (error) {
      console.log(error);
      return this.service.toastErr("Please Enter valid address.");
    }

    console.log(data);


    $("#add-location").modal("hide");
    this.spinner.show();
    this.service.postApi("customer/address", data).subscribe(
      (res: any) => {
        this.spinner.hide();
        console.log("response", res);
        if (res.status == 200) {
          this.addAddressForm.reset();
          this.getAlladdedAddress();
          this.selectedTab = "choosepayment";
          console.log("selected TAb", this.selectedTab);
        } else {
          this.service.toastErr(res.bode.message);
          return;
        }
      },
      (err) => {
        this.spinner.hide();
        this.service.toastErr(err.error.message);
      }
    );
  }

  getAlladdedAddress() {
    this.spinner.show();
    this.service
      .getApi("customer/get-nearby-addresses/" + this.outletId)
      .subscribe(
        (res: any) => {
          console.log("address response", res);
          if (res.status == 200) {
            this.getAllAddress = res.body;
            this.getDeliveryAddress = res.body[0];
            setTimeout(() => {
              this.spinner.hide();
            }, 1000);
            console.log(" this.getDeliveryAddress", this.getDeliveryAddress);
          } else {
            this.spinner.hide();
          }
        },
        (err) => {
          this.spinner.hide();
          if (err.status == 403) {
            this.service.toastErr(err.error.detail);
            let cookie_value = localStorage.getItem("cookie");
            localStorage.clear();
            localStorage.setItem("cookie", cookie_value);
            this.router.navigateByUrl("");
          }
        }
      );
  }

  getDeliverySlot() {
    $("#delivery-slot").modal("show");
    this.spinner.show();
    this.service
      .getApi("customer/delivery-slot/" + this.rest_id)
      .subscribe((res: any) => {
        console.log("this.getDeliverySlot response", res);
        this.spinner.hide();
        if (res.status == 200) {
          this.deliverHereApi();
          this.getAllDeliverySlot = res.body.opertion_hours;

          console.log(" this.getDeliverySlot");
        }
      });
  }

  changeDeliverySlot(slot, time, schedule) {
    
    // if (slot && time && schedule) {
    //   let date = slot.date;
    //   let time1 = time.end_time;
    //   this.Isschedule = schedule;
    //   this.delivery_date_time = date + "T" + time1;
    //   console.log(
    //     "time",
    //     this.delivery_date_time,
    //     "this.Isschedule",
    //     this.Isschedule
    //   );
    // } else {
    //   this.Isschedule = false;
    //   let date = new Date();
    //   let splitDate = date.toLocaleString().split(":");
    //   let currentMin = date.getMinutes();
    //   splitDate[1] = JSON.stringify(this.delivery_time + currentMin);
    //   let joinDate = splitDate.join(":");
    //   let splitJoin = joinDate.split("/");
    //   let joinspitDate = splitJoin[0] + "-" + splitJoin[1] + "-" + splitJoin[2];
    //   let split1 = joinspitDate.split(",");
    //   let reverseDate = split1[0].split("-");
    //   var finalDate =
    //     reverseDate[2] +
    //     "-" +
    //     reverseDate[1] +
    //     "-" +
    //     reverseDate[0] +
    //     "T" +
    //     split1[1].slice(1, 6);
    //   console.log("finalDate", finalDate);
    //   let newSplit1 = split1[1].slice(1, 6);
    //   let splitMinute = newSplit1.split(":")[1];
    //   let numsplitMinute: any = Number(splitMinute);
    //   console.log("numsplitMinute", numsplitMinute);
    //   if (numsplitMinute > 59) {
    //     numsplitMinute = numsplitMinute % 60;
    //     if (numsplitMinute < 10) {
    //       numsplitMinute = "0" + JSON.stringify(numsplitMinute);
    //       console.log("numsplitMinute", numsplitMinute);

    //       // numsplitMinute = JSON.parse(numsplitMinute) ;
    //     }
    //     if (typeof numsplitMinute === "number") {
    //       numsplitMinute = JSON.stringify(numsplitMinute);
    //     }
    //     finalDate =
    //       reverseDate[2] +
    //       "-" +
    //       reverseDate[1] +
    //       "-" +
    //       reverseDate[0] +
    //       "T" +
    //       newSplit1.split(":")[0] +
    //       ":" +
    //       numsplitMinute;
    //   }
    //   this.delivery_date_time = finalDate;
    //   console.log("delivery_date_time", this.delivery_date_time);
    // }
    let date = new Date(new Date().setMinutes(new Date().getMinutes() + this.delivery_time)).toLocaleString()

    let [str, times] = date.split(",")
    let newDate = str.split('/').reverse().toString()
    newDate = newDate.replace(/,/g,'-')

    let newTime = times.split(":")
    let newTime2 = `${newTime[0].trim()}:${newTime[1].trim()}`

    this.delivery_date_time = `${newDate.trim()}T${newTime2.trim()}`
     this.Isschedule = false;
    // 2021-02-23T12:25
  }

  addDeliveryAddressForm() {
    const add = this.addNewAddressForm.get("address") as FormArray;
    // let fg =  this.formBuilder.group({
    //   addressName: [user ? user.addressName:''],
    // });
    add.push(
      this.formBuilder.group({
        addressName: [],
      })
    );
    // (<FormArray>this.addNewAddressForm.get('address')).push(fg);
  }

  otp() {
    this.otpForm = this.formBuilder.group({
      phnumber: new FormControl(""),
      otp: new FormControl("", Validators.compose([Validators.required])),
    });
  }

  phoneCheckCountry() {
    $("#phoneNum1").intlTelInput({
      autoPlaceholder: false,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: "in",
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: ["us"],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false,
    });
  }

  toCheckSpaceChar() {
    this.isValidNumber = $("#phoneNum1").intlTelInput("isValidNumber");
    const countryData = $("#phoneNum1").intlTelInput("getSelectedCountryData");
    console.log(" this.isValidNumber", this.isValidNumber);
    console.log("countryData", countryData);

    this.myCode = "+" + countryData.dialCode;
  }

  CheckSpaceChar() {
    this.isValidNumber = $("#cellNum").intlTelInput("isValidNumber");
    const countryData = $("#cellNum").intlTelInput("getSelectedCountryData");
    console.log("countryData", countryData);
    this.myCode = "+" + countryData.dialCode;
  }

  cellCheckCountry() {
    $("#cellNum").intlTelInput({
      autoPlaceholder: false,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: "in",
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: ["us"],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false,
    });
  }
  extractCountryCode(regexString) {
    // Match the country code pattern within the string
    const match = regexString.match(/\\\+(\d+)/);
    
    if (match) {
      return `+${match[1]}`; // Return the extracted country code
    } else {
      return null; // Return null if no match found
    }
  }
  login() {
    var data = {
      country_code: this.extractCountryCode(this.loginForm.value.customer_pcode),
      mobile: this.loginForm.value.phone,
    };
    this.spinner.show();
    this.service.postApi("customer/login", data).subscribe((res: any) => {
      this.spinner.hide();
      console.log("this.res", res);

      if (res.status == 200) {
        this.service.showSuccess(res.body.message);
        this.loginMessage = res.body.message;
        console.log("login message", this.loginMessage);
        this.loginForm.reset();
        this.uuid = res.body.uuid;
        localStorage.setItem("uuid", this.uuid);
        localStorage.setItem("is_signup", res.body.is_signup);
        this.mobile = res.body.mobile;
        console.log("this.mobile", this.mobile);

        this.otpForm.patchValue({
          phnumber: this.mobile,
        });
        this.resendTimer();
      } else if (res.status == 201) {
        this.loginMessage1 = res.body.message;
        console.log("login message1", this.loginMessage1);
        this.loginForm.reset();
        this.changeTab("step2", "");
        this.selectedTab = "step2";
        this.mobile = res.body.mobile;
        this.signupForm.patchValue({
          phoneNo: this.mobile,
        });
      }
    });
  }

  signupPage() {
    this.header.signupPage();
  }
  loginModalOpen() {
    this.header.loginModalOpen();
  }

  signUpModal() {
    let full_name = this.signupForm.value.name;
    let arr = full_name.split(" ");
    let f_name = arr[0];
    arr.splice(0, 1);
    let l_name = arr.join(" ");
    var data1 = {
      first_name: f_name,
      mobile: this.signupForm.value.phoneNo,
      email: this.signupForm.value.email,
      password: this.signupForm.value.password,
      // "country_code": this.myCode,
      country_code: this.signupForm.value.customer_signup_pcode,
      last_name: l_name,
    };
    console.log("policy", this.signupForm.value.policy);
    console.log("Terms", this.signupForm.value.terms);
    console.log("Sign up form", data1);

    this.spinner.show();
    this.service.postApi("customer/signup", data1).subscribe(
      (res: any) => {
        this.spinner.hide();
        console.log("signup response", res);
        if (res.status == 201) {
          this.signUpMessage = res.body.message;
          this.resendTimer();
          this.cell = res.body.mobile;
          this.uuid = res.body.uuid;
          localStorage.setItem("uuid", this.uuid);
          this.selectedTab = "step2";
          this.viewProfile();
          this.otpForm.patchValue({
            phnumber: this.cell,
          });
        } else {
          this.service.toastErr("Please fill all details");
          return;
        }
      },
      (error) => {
        this.spinner.hide();
        console.log("error", error);
        if (error.status == 401 || error.status == 403 || error.status == 400) {
          this.service.toastErr(error.error.detail || error.error.message);
          return;
        }
      }
    );
  }

  changeTab(tab, status) {
    console.log("tab", tab);
    if (tab == "step1") {
      this.phoneCheckCountry();
      this.selectedTab = tab;
    } else if (tab == "step3") {
      this.cellCheckCountry();
      this.selectedTab = tab;
    } else if (tab == "step2") {
      this.cellCheckCountry();
      if (this.loginForm.invalid) {
        if (
          this.signupForm.valid &&
          this.signupForm.value.terms === true &&
          this.signupForm.value.policy === true
        ) {
          this.signUpModal();
        } else {
          this.service.toastErr("Fill the valid form details");
          return;
        }
      } else {
        this.login();
        this.selectedTab = tab;
      }
    } else if (tab == "afterlogin") {
      if (this.loginForm.valid || this.signupForm.valid || this.otpForm.valid) {
        this.verify();
      }
      this.applyDiscountOnItem();

      this.selectedTab = tab;
    } else if (tab == "choosepayment") {
      this.getAlladdedAddress();
      if (this.addAddressForm.valid) {
        this.addNewAddresFun();
        this.selectedTab = tab;
        this.selectedTab = "choosepayment";
        this.applyDiscountOnItem();
      } else {
        if (status == "afterDelivery") {
          this.selectedTab = tab;
          this.applyDiscountOnItem();
        } else {
          this.service.toastErr("please fill all valid details");
        }
      }
    } else if (tab == "choosePaymentMethod") {
      this.selectedTab = tab;
      this.userDeliverAddress = status;
      this.deliverHereApi();
      this.applyDiscountOnItem();
    }
  }

  changePaymentMethod(method) {
    this.paymentMethod = method;
    this.getCart();
  }

  paymentCardFormValidation() {
    this.paymentCardForm = new FormGroup({
      cardNumber: new FormControl("", Validators.required),
      expiry: new FormControl("", Validators.required),
      cvv: new FormControl("", Validators.required),
      nameOncard: new FormControl("", Validators.required),
    });
  }

  // Deliver Here APi integration

  deliverHereApi() {
    this.adddress_id = this.userDeliverAddress.id;
    this.spinner.show();
    console.log("outletId", this.outletId, "address_id", this.adddress_id);
    if (this.adddress_id != "" && this.outletId != "") {
      this.service
        .getApi(
          `customer/select-delivery-address/${this.adddress_id}/outlet/` +
            this.outletId
        )
        .subscribe(
          (res: any) => {
            console.log("--------->", res);

            if (res.status == 200) {
              
              let data = res.body.data;
              this.delivery_time = data.delivery_time;
              if (this.delivery_time > 60) {
                this.delivery_time = this.delivery_time % 60;
              }
              // setTimeout(() => {
              //   this.spinner.hide();
              // }, 1000);
              this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          },
          (err) => {
            this.spinner.hide();
            if (err.status == 403) {
              this.service.toastErr(err.error.detail);
              let cookie_value = localStorage.getItem("cookie");
              localStorage.clear();
              localStorage.setItem("cookie", cookie_value);
              this.router.navigateByUrl("");
            } else if (err.status == 400) {
              this.service.toastErr(err.error.message);
            } else if (err.status == 401) {
              this.service.toastErr(err.error.message);
            }
          }
        );
    }
  }

  // Cash on Delivery payment Api integrate here

  paymentApi() {
    if (this.Isschedule == false) {
      this.changeDeliverySlot("", "", "");
    }
    this.cartArr.forEach((ele) => {
      this.selectedItemsArray.push({
        id: ele.id,
        price: ele.total_pay * ele.quantity,
      });
    });
    this.cartArr.forEach((ele) => {
      this.order_Items.push({
        items: ele.id,
        quantity: ele.quantity,
        options:
          ele.item_option.length != 0
            ? JSON.stringify(ele.item_option[0].id)
            : "",
        modifiers: ele.selected_modifiers ? ele.selected_modifiers : [],
      });
    });
    let amount_payable =
      this.cartSubTotal +
      this.getDeliveryCharge +
      (this.cartSubTotal * this.getTax) / 100 -
      this.discount;

    let data = {
      restaurant: this.rest_id,
      address: this.outletId, // outlet id
      total_items: this.cartArr.length,
      discount: this.discount,
      order_value: this.cartSubTotal,
      delivery_charges: this.getDeliveryCharge,
      tax_charges: (this.cartSubTotal * this.getTax) / 100,
      amount_payable: amount_payable,
      is_schedule: this.Isschedule,
      // delivery_date_time: this.datePipe.transform(
      //   this.delivery_date_time,
      //   "yyyy-MM-ddThh:mm:ss.sssZ"
      // ), 
      // delivery_date_time: this.delivery_date_time, 
      delivery_date_time:new Date( this.delivery_date_time).toISOString(), 
      
      // this.delivery_date_time, // 2020-10-28T21:11:28.291Z date.toISOString()
      // "estimated_time":"00:"+JSON.stringify(this.delivery_time)+":00", //
      estimated_time: this.datePipe.transform(
        this.delivery_time,
        "hh:mm:ss.sssZ"
      ),
      payment_method: "COD",
      offer: this.offerId,
      special_note: this.suggession,
      order_items: this.order_Items,
      delivery_address: this.adddress_id,
    };

    console.log("Customer data", data);
    this.service.showSpinner();
    this.service.postApi("customer/place-order", data).subscribe(
      (res: any) => {
        console.log("Customer response", res);
        this.service.hideSpinner();
        if (res.status == 201) {
          this.service.showSuccess("Place order successfully");

          let trackOrder = {
            orderId: res.body.id,
            restaurant_lat: res.body.restaurant_address.latitude,
            restaurant_lng: res.body.restaurant_address.longitude,
            address_lat: this.userDeliverAddress.latitude,
            address_lng: this.userDeliverAddress.longitude,
            order_status: "Order Placed",
          };
          console.log("track Order", trackOrder);
          localStorage.setItem("trackOrder", JSON.stringify(trackOrder));
          this.router.navigateByUrl("/thankyou");
          localStorage.removeItem("myCart");
          localStorage.removeItem("restaurantDetail");
          this.service.cartLength.next("0");
        }
      },
      (err) => {
        this.service.hideSpinner();
        if (err.status == 400) {
          if (err.error.message) {
            this.service.toastErr(err.error.message);
          } else if (err.error.details) {
            this.service.toastErr(err.error.details);
          }
        } else if (err.status == 403) {
          this.service.toastErr(err.error.details);
        }
      }
    );
  }

  ngOnDestroy() {
    clearInterval(this.x);
  }

  resendTimer() {
    this.timer = 60;
    this.x = setInterval(() => {
      this.timer = this.timer - 1;
      if (this.timer == 0) {
        clearInterval(this.x);
      }
    }, 1000);
  }

  //-------resend otp -------------//
  resendOtp() {
    let url = `customer/resend-otp/${this.uuid}`;
    this.service.getApi(url).subscribe(
      (res: any) => {
        if (res.status == 200) {
          this.resendTimer();
        }
      },
      (err) => {
        if (err.status == 400) {
          this.service.toastErr(err.error.message);
        }
      }
    );
  }

  verify() {
    var data = {
      code: this.otpForm.value.otp,
    };
    this.spinner.show();
    this.uuid = localStorage.getItem("uuid");
    this.service
      .postApi(`customer/otp-verification/${this.uuid}`, data)
      .subscribe(
        (res: any) => {
          this.spinner.hide();
          if (res.status == 200) {
            $("#otpModal").modal("hide");
            localStorage.setItem("token", res.body.token);
            let token = localStorage.getItem("token");
            this.service.token.next(token);
            localStorage.setItem("firstname", res.body.first_name);
            this.applyDiscountOnItem();
            let profile = localStorage.getItem("firstname");
            setTimeout(() => {
              this.header.checkToken();
            }, 1000);
            this.service.profile1.next(profile);
            localStorage.setItem("user_id", res.body.user_id);
            if (localStorage.getItem("token")) {
              this.selectedTab = "afterLogin";
              this.getDeliveryCharges();
              this.viewProfile();
              this.getAlladdedAddress();

              this.myAddress = localStorage.getItem("address");
            } else {
              this.selectedTab = "delivery";
            }
            this.checkToken();
            // if (localStorage.getItem('latitude') && localStorage.getItem('longitude')) {
            //   // this.router.navigate['/nearyou']
            // } else {
            //   // this.router.navigate['/home']

            // }
            // if (this.loginMessage == 'OTP has been sent to your register number.') {
            //   this.loggedIn = 'afterlogin';
            //   this.token = localStorage.setItem("token", res.body.token);
            //   this.firstName = res.body.first_name,

            //   this.router.navigateByUrl('/nearyou')
            //   localStorage.setItem('firstname',this.firstName)
            // }
            // else if (this.signUpMessage == 'Account created successfully.') {
            //   this.loggedIn = 'beforelogin'

            //   this.router.navigateByUrl('/home')
            // }
          }
        },
        (err) => {
          console.log(err);

          this.service.hideSpinner();
          if (err.status == 400) {
            this.service.toastErr(err.error.message);
            this.changeTab("step2", "");
            this.selectedTab = "step2";
            this.resendTimer();
          }
        }
      );
  }

  checkToken() {
    this.spinner.show();
    this.token = localStorage.getItem("token")
      ? localStorage.getItem("token")
      : "";
    this.spinner.hide();
    if (this.token) {
      this.firstName = localStorage.getItem("firstname");
    }
    console.log("Token", this.token);
  }

  // Note: This example requires that you consent to location sharing when
  // prompted by your browser. If you see the error "The Geolocation service
  // failed.", it means you probably did not give permission for the browser to
  // locate you.

  initMap() {
    if (!navigator.onLine) {
      this.service.toastErr("You are offline!");
      return;
    }

    if (!navigator.geolocation) {
      this.service.toastErr("Geolocation is not supported by this browser.");
      return;
    }
    let lat = localStorage.getItem("latitude")
      ? localStorage.getItem("latitude")
      : "";
    let lng = localStorage.getItem("longitude")
      ? localStorage.getItem("longitude")
      : "";
    console.log("lat", lat, "lng", lng);

    if (lat != "" && lng != "") {
      this.lat = Number(lat);
      this.lng = Number(lng);
    } else {
      this.spinner.show();
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.spinner.hide();
      });
    }
  }

  CountryCode() {
    let channel = "api/country-list";
    this.service.getApi(channel).subscribe((res: any) => {
      this.CountryCodeItems = res.body;
      console.log("Code", res);
    });
  }

  handleLocationError(
    browserHasGeolocation: boolean,
    infoWindow: google.maps.InfoWindow,
    pos: google.maps.LatLng
  ) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    infoWindow.open(this.map);
  }

  //================ADD TO CARD =============================//
  addCard() {
    let x = this.paymentCardForm.value.expiry.slice(0, 2) - 1;
    let y = 20 + this.paymentCardForm.value.expiry.slice(2, 4);
    let mydate = new Date(y, x);
    let data = {
      name: this.paymentCardForm.value.nameOncard,
      number: this.paymentCardForm.value.cardNumber,
      expire_date: this.datePipe.transform(mydate, "yyyy-MM-dd"),
      //"expire_date":this.datePipe.transform(this.paymentCardForm.value.expiry,'yyyy-MM-dd'),
    };

    this.service.showSpinner();
    this.service.postApi(`customer/card`, data).subscribe(
      (res) => {
        this.service.hideSpinner();
        $("#add-new-cart").modal("hide");
        this.getCart();
      },
      (err) => {
        this.service.hideSpinner();
      }
    );
  }
  getCart() {
    this.service.showSpinner();
    this.service.getApi("customer/card").subscribe(
      (res) => {
        this.service.hideSpinner();
        if (res.status == 200) {
          console.log(res);
          this.getCartListArr = res.body;
          this.cardId = res.body.id;
          console.log(this.cardId);
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrToast(err.error.message);
      }
    );
  }

  deleteCard() {
    this.service.showSpinner();
    this.service.deleteApi(`customer/card/${this.cardId}`).subscribe(
      (res) => {
        if (res.status == 204) {
          this.service.showSuccToast("Card Deleted Successfully");
          this.getCart();
        }
      },
      (err) => {
        this.service.hideSpinner();
        this.service.showErrToast(err.error.message);
      }
    );
  }
  deletePayment(id) {
    this.cardId = id;
    $("#Alert-card").modal("show");
  }

  getOptions(arr){
    return arr.filter((ele)=>{return ele.selected})
  }
}
